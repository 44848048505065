<template>
  <div class="mt-20 w-full flex max-w-6xl mx-auto justify-center">
    <StatsSummary> </StatsSummary>

    <div class="w-1/3 p-8 border rounded mr-2">
      <h1 style="color: #cfb47e" class="font-bold text-md uppercase">
        customers
      </h1>

      <div class="flex mt-4">
        <div class="w-1/2 text-left">
          <span class="font-bold text-xl">273</span>
          <p class="text-xs">Total Customers</p>
        </div>
        <div class="w-1/2 text-left">
          <span class="font-bold text-xl flex items-center"
            >12
            <svg
              class="ml-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#00bc1f"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M12 19V6M5 12l7-7 7 7" /></svg
          ></span>
          <p class="text-xs">New Customers</p>
        </div>
      </div>

      <div class="mt-12">
        <h1 style="color: #cfb47e" class="font-bold text-md uppercase">
          Most Engagements
        </h1>

        <div class="flex mt-4">
          <div class="w-1/2 text-left">
            <span class="font-bold text-sm">Lilybeach</span>
            <p class="text-xs">Ahsan Musthafa</p>
          </div>
          <div class="w-1/2 text-right">
            <span class="font-semibold text-sm"> 123 </span>
          </div>
        </div>

        <div class="flex mt-4">
          <div class="w-1/2 text-left">
            <span class="font-bold text-sm">CrossRoad Maldives</span>
            <p class="text-xs">Hassan Vasyu</p>
          </div>
          <div class="w-1/2 text-right">
            <span class="font-semibold text-sm"> 98 </span>
          </div>
        </div>
      </div>
    </div>

    <RecentInquiries> </RecentInquiries>
  </div>

  <CategoryStats></CategoryStats>
</template>

<script>
import StatsSummary from "@/components/modules/dashboard/StatsSummary";
import CategoryStats from "@/components/modules/dashboard/CategoryStats";
import RecentInquiries from "@/components/modules/dashboard/RecentInquiries";

export default {
  name: "Dashboard",
  data() {
    return {};
  },
  methods: {
    filterOverlayClicked() {
      this.filterVisible = false;
    },
    FileUploadOverlayClicked() {
      this.FileUploadVisible = false;
    },
    ImageUploadOverlayClicked() {
      this.ImageUploadVisible = false;
    },
  },
  components: {
    StatsSummary,
    CategoryStats,
    RecentInquiries,
  },
};
</script>


<style>
</style>