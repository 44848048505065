<template>
  <div class="w-1/3 p-8 border rounded">
    <h1 style="color: #cfb47e" class="font-bold text-md uppercase">
      recent inquiries
    </h1>

    <div class="border-b pb-6">
      <div class="flex mt-4">
        <div class="w-1/2 text-left">
          <span class="font-bold text-sm">EXPG275</span>
          <p class="font-bold text-sm">Vilureef</p>
          <p class="text-xs font-normal">Ahsan Musthafa</p>
        </div>

        <div class="w-1/2 text-right ml-10">
          <span
            class="font-normal text-sm text-yellow-400 flex items-center mb-2"
          >
            <svg
              class="bg-yellow-50 rounded-full p-1 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ffed16"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="2" x2="12" y2="6"></line>
              <line x1="12" y1="18" x2="12" y2="22"></line>
              <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
              <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
              <line x1="2" y1="12" x2="6" y2="12"></line>
              <line x1="18" y1="12" x2="22" y2="12"></line>
              <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
              <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>

            Pending
          </span>
          <span class="font-normal text-xs text-right mr-3"> 05 May 2021</span>
        </div>
      </div>
    </div>

    <div class="border-b pb-6">
      <div class="flex mt-4">
        <div class="w-1/2 text-left">
          <span class="font-bold text-sm">EXPG275</span>
          <p class="font-bold text-sm">Vilureef</p>
          <p class="text-xs font-normal">Ahsan Musthafa</p>
        </div>

        <div class="w-1/2 text-right ml-10">
          <span class="font-normal text-sm text-red-400 flex items-center mb-2">
            <svg
              class="bg-red-50 rounded-full p-1 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#e04054"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>

            Pending
          </span>
          <span class="font-normal text-xs text-right mr-3"> 05 May 2021</span>
        </div>
      </div>
    </div>

    <div class="border-b pb-6">
      <div class="flex mt-4">
        <div class="w-1/2 text-left">
          <span class="font-bold text-sm">EXPG254</span>
          <p class="font-bold text-sm">Sun Siyam Olhuveli Beach</p>
          <p class="text-xs font-normal">Mohamed Najah</p>
        </div>

        <div class="w-1/2 text-right ml-10">
          <span
            class="font-normal text-sm text-blue-500 flex items-center mb-2"
          >
            <svg
              class="bg-blue-50 rounded-full p-1 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#408ce0"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>

            Approved
          </span>
          <span class="font-normal text-xs text-right mr-3"> 05 May 2021</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecentInquiries",
  data() {
    return {};
  }
};
</script>