<template>
  <div class="w-2/3">
    <div class="p-4 border rounded mr-2">
      <h1 style="color: #cfb47e" class="font-bold text-md uppercase">
        summary
      </h1>

      <div class="relative">
        <img class="pr-16 pl-16" src="@/assets/dashboardsummary.png" />

        <div
          class="
              absolute
              text-right
              top-6
              left-8
              cursor-pointer
              transition
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1
              hover:scale-110
            "
        >
          <span class="font-bold text-xl text-indigo-400"> 123 </span>
          <p class="font-semibold text-indigo-400">Approved</p>
        </div>

        <div
          class="
              absolute
              top-6
              right-12
              cursor-pointer
              transition
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1
              hover:scale-110
            "
        >
          <span class="font-bold text-xl text-yellow-500"> 123 </span>
          <p class="font-semibold text-yellow-500">On hold</p>
        </div>

        <div
          class="
              absolute
              top-32
              right-0
              cursor-pointer
              transition
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1
              hover:scale-110
            "
        >
          <span class="font-bold text-xl text-red-600"> 123 </span>
          <p class="font-semibold text-red-600">Rejected</p>
        </div>

        <div
          class="
              absolute
              top-32
              left-8
              cursor-pointer
              transition
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1
              hover:scale-110
            "
        >
          <span class="font-bold text-xl text-blue-500"> 123 </span>
          <p class="font-semibold text-blue-500">Sent</p>
        </div>

        <div
          class="
              absolute
              mt-2
              top-56
              right-14
              cursor-pointer
              transition
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1
              hover:scale-110
            "
        >
          <span class="font-bold text-xl text-green-600"> 123 </span>
          <p class="font-semibold text-green-600">Closed</p>
        </div>

        <div
          class="
              absolute
              text-right
              mt-2
              top-56
              left-12
              cursor-pointer
              transition
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1
              hover:scale-110
            "
        >
          <span class="font-bold text-xl text-yellow-400"> 123 </span>
          <p class="font-semibold text-yellow-400">Pending</p>
        </div>
      </div>
    </div>
    <div class="p-4 border rounded mr-2 mt-2">
      <h1 style="color: #cfb47e" class="font-bold text-md uppercase">
        inquiries
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsSummary",
  data() {
    return {};
  }
};
</script>